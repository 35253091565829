import { AssetManagementConfig } from 'components/asset-management/types/asset-management-config.type';
import { CREATIVE_MANAGEMENT_DEFAULT_SETUP } from './default-setup';
import { CreativeSubType } from '../types/creative-sub-type.type';

const CREATIVE_MANAGEMENT_CONFIG: AssetManagementConfig = {
    storeName: 'CreativeManagementV2',
    type: 'creative',
    gridType: 'grid',
    dropZone: false,
    versioning: true,
    setupResource: 'setup_creativeManagement_v2',
    assetsPlaceholder: 'media',
    assetsListViewHeaderKeys: ['empty', 'title', 'type', 'content', 'lastModified', 'empty'],
    subtypeIcons: {
        displayAdDesigned: 'web_outlined',
        dynamicVideoDesigned: 'video_gallery',
        dynamicImageDesigned: 'image_outlined',
        dynamicPDFDesigned: 'picture_as_pdf_outlined',
        dynamicAfterEffects: 'movie_filter_outlined',
        dynamicInDesign: 'picture_as_pdf',
        displayAd: 'web_outlined',
        dynamicImage: 'image_outlined',
        dynamicVideo: 'video_gallery',
        dynamicPDF: 'picture_as_pdf_outlined',
        socialChannelItem: 'description',
        staticAssetDisplay: 'description',
        staticAssetImage: 'description',
        staticAssetVideo: 'description',
        staticAssetAudio: 'description'
    }
};

const TD_CREATIVE_TYPES: CreativeSubType[] = [
    'dynamicImageDesigned',
    'dynamicVideoDesigned',
    'displayAdDesigned',
    'dynamicPDFDesigned',
    'dynamicAfterEffects',
    'dynamicInDesign'
];

export { CREATIVE_MANAGEMENT_CONFIG, CREATIVE_MANAGEMENT_DEFAULT_SETUP, TD_CREATIVE_TYPES };
